
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
  import { Suggestion } from "@/models/suggestions";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds, mapApiDataMerger } from "@/utils/apiDataHelper"
  import { resourceUrl } from '@/utils/dataExtractors'
  import Search from "@/components/shared/Search.vue"
  import { providerSuggestionIndexColumns as columns } from '@/view_scripts/table_columns/suggestion_columns'

  export default defineComponent({
    name: 'provider_suggestions_index',
    components: {
      Search,
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.suggestions.pagination.totalItems)
      const data = ref<any[]>([])
      const getItems = async(ctx: BvTableCtxObject) => {
        const suggestionQueryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
        }
        const suggestionResponse = await root.$store.dispatch('suggestions/load', suggestionQueryParams)
        const segmentIds = extractRelationshipIds(suggestionResponse, 'segment')
        const segmentQueryParams = {
          'filter[id]':segmentIds.join(','),
          'page[size]': segmentIds.length*2,
        }
        const segmentResponse = await root.$store.dispatch('segments/load', segmentQueryParams)
        const cgiIds = extractRelationshipIds(segmentResponse, 'care_giver_instance')
        const cgiQueryParams = {
          'filter[id]':cgiIds.join(','),
          'page[size]': segmentIds.length*2,
          include: 'care_giver'
        }
        const cgiResponse = await root.$store.dispatch('care_giver_instances/load', cgiQueryParams)
        mapApiDataMerger(segmentResponse.data, cgiResponse.data, 'care_giver_instance')
        mapApiDataMerger(suggestionResponse.data, segmentResponse.data, 'segment')
        data.value =  suggestionResponse.data
        return suggestionResponse.data
      }

      // const columns = ref(providerSuggestionIndexColumns)

      const searchFunction = async (query: {[key: string]: string}) => {
        return root.$store.dispatch('contracts/search', query)
      }

      const searchColumns = [
        // {
        //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        //   key: "meta.designation",
        //   title: "Haushalt",
        //   options: {hrefFunction: (data: any) => resourceUrl(data.item)}
        // }
      ]

      return {
        data,
        getItems,
        totalItems,
        columns,
        searchColumns,
        searchFunction
      }
    }
  })
