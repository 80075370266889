import moment from 'moment'
import { stringSort } from "@/utils/helper"

export function fullName(data: any) {
  if (!data || data === '') return 'Name fehlt'
  const fn = data.attributes?.firstName
  const ln = data.attributes?.lastName
  const result = [fn, ln].join(' ').trim()
  return result === '' ? 'Name fehlt' : result
}

export function fullSalute(data: any) {
  if (!data || data === '') return 'Name fehlt'
  const g = data.attributes?.gender
  if (g === 'm') {
    return 'Herr '+fullName(data)
  } else if (g === 'f') {
    return 'Frau '+fullName(data)
  } else {
    return fullName(data)
  }
}

export function defaultPhone(data: any) {
  const phones = data.phones
  if (!phones || phones.length === 0) {return {attributes: {number: ''}}}
  const defaultPhones = phones.filter(p => p.default)
  return defaultPhones.length > 0 ? defaultPhones[0] : phones[0]
}

export function defaultEmail(data: any) {
  const emails = data.emails
  if (!emails || emails.length === 0) {return {attributes: {email: ''}}}
  const defaultEmails = emails.filter(e => e.default)
  return defaultEmails.length > 0 ? defaultEmails[0] : emails[0]
}

export function streetWithNumber(data: any) {
  return [data.street, data.houseNumber].join(' ').trim()
}

export function zipCodeAndCity(data: any) {
  return [data.zipCode, data.city].join(' ').trim()
}

export function resourceUrl(resource: any) {
  return `/${resource.type}/${resource.id}`
}

export const fileSize = (size) => {
  if (size < 1000000) {
    return Math.round(size/1000).toString()+' kB'
  } else if (size < 1000000000 ) {
    return Math.round(size/1000000).toString()+' MB'
  } else {return 'File zu Groß'}
}

export const lastTicketActivity = (ticket: any) => {
  const msgStamps = ticket.attributes.messages?.map((m: any) => m.updatedAt) || []
  const timeStamps = msgStamps.concat([ticket.attributes.updatedAt])
  const lastActivity = stringSort(timeStamps, (t) => t, false)[0]
  return lastActivity
}