import moment from 'moment'

export const stringSort = (dataArray: any[], getterFunction=((i)=>i), asc=true) => {
  return dataArray.sort((a,b) => {
    if (getterFunction(a)>getterFunction(b)) {
      return asc ? 1 : -1
    } else if (getterFunction(a)<getterFunction(b)) {
      return asc ? -1 : 1
    } else {
      return 0
    }
  })
}

export const shortDate = (date:string|null) => {
  if (!date || date === '') return 'kein Datum'
  return moment(date).locale('de').format('ll')
}

export const dateTime = (date:string|null) => {
  if (!date || date === '') return 'kein Datum'
  return moment(date).locale('de').format('lll')
}

export const ageFromDate = (date: string) => {
  const birthday = Date.parse(date)
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const secsToHrsnMins = (secs: number) => {
  return (new Date(secs * 1000).toISOString().substr(11, 5))
}

export const hmsString = (secs: number, units: boolean) => {
  const int = Math.abs(secs)
  const h = Math.floor( int/3600 )
  const m = Math.floor((int%3600)/60)
  const s = Math.floor(int%3600%60)
  return (secs<0 ? '-' : '') + (units ? `${h}h:${m}m:${s}s` : `${h}:${m}:${s}`)
}

export const intersect = (a:any[], b:any[]):any[] => a.filter(Set.prototype.has, new Set(b))

export const localIndex = (data: any, keyGetter: Function) => {
  const retVal = {}
  data.forEach((e) => {
    const key = keyGetter(e)
    if (!retVal[key]) {
      retVal[key] = [e]
    } else {
      retVal[key].push(e)
    }
  })
  return (key) => retVal[key]
}

export const round = (val: number, places:number=2) => {
  const fac = 10**places
  return Math.round(fac * val)/fac
}

export const generateYearSelectOptions = (startYear=2017) => {
  let begin = startYear
  const current = new Date().getFullYear();
  const years: number[] = [];
  while (begin <= current) { years.push(begin++); }
  return years.reverse();
}

export const generateMonthSelectOptions = () => {
  // const def = {text:'Monat wählen', value: null}
  const opts = [...Array(12).keys()].map((n) => ({
    text: new Date(2000, n, 1).toLocaleString('De', { month: 'long' }),
    value: n+1
  }))
  // opts.unshift(def)
  return opts
}

export const defObj = (defaultValueProviderFunction: Function) => {
  const handler = {
    get: (obj, prop) => prop in obj ? obj[prop] : defaultValueProviderFunction()
  }
  return new Proxy({}, handler);
}

export const nextMonthStart = (date: string):string => {
  const d = new Date(date)
  const offset = d.getTimezoneOffset()/-60
  return new Date(d.getFullYear(), d.getMonth()+1,1,offset).toISOString()
}
export const endOfMonth = (date: string):Date => {
  const year = new Date(date).getUTCFullYear()
  // +1 because it will use the previous month with the 0, so plus one to get the current month
  const month = new Date(date).getUTCMonth()+1
  const localOffsetInMinutes = (new Date).getTimezoneOffset()
  return new Date(year, month, 0, 12, localOffsetInMinutes)
}

export const removePolishCompanyType = (companyName: string) => {
  if (!companyName) return
  const pattern = /\s*(Sp\. z o\.o\.|S\.A\.|Sp\.k\.|Sp\.j\.|S\.C\.|P\.H\.U\.|P\.P\.|Z\.O\.O\.|S\.K\.A\.)$/
  return companyName?.replace(pattern, '').trim()
}