import { resourceUrl, fullName } from '@/utils/dataExtractors'
export const suggestionColumns = (actions:any) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "attributes.stage",
      title: "Status",
      sortable: true,
      options: {
        hrefFunction: (data: any) => {
          return resourceUrl(data.item)
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.visor.attributes.agency.attributes.name",
      title: "Agentur",
      sortable: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.createdAt",
      title: "Erhalten",
      sortable: true,
      options: {type: 'datetime'}
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "attributes.segment.attributes.careGiverInstance",
      title: "BK",
      sortable: true,
      options: {
        contentFunction: (data: any) => {
          const tmp = data.item.attributes?.segment?.attributes?.careGiverInstance?.attributes?.care_giver
          if (tmp) {return fullName(tmp)}
        },
        hrefFunction: (data: any) => {
          const tmp = data.item.attributes?.segment?.attributes?.careGiverInstance
          if (tmp) {return resourceUrl(tmp)}
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.presentedAt",
      title: "Vorgestellt",
      sortable: true,
      options: {type: 'datetime'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.segment.attributes.cgiArrival",
      title: "Anreise am",
      sortable: true,
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.rejectionReason",
      title: "Absage Grund",
      sortable: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bestätigen',
            icon: ['fad', 'user-check'],
            action: actions.accept,
          },
          {
            title: 'Abbrechen',
            icon: ['fad', 'user-slash'],
            action: actions.reject,
          }
        ]
      }
    }
  ]
}

export const suggestionProviderColumns = (actions:any) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "attributes.stage",
      title: "Status",
      sortable: true,
      options: {
        hrefFunction: (data: any) => {
          return resourceUrl(data.item)
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "attributes.segment.attributes.careGiverInstance",
      title: "BK",
      sortable: true,
      options: {
        contentFunction: (data: any) => {
          const tmp = data.item.attributes?.segment?.attributes?.careGiverInstance?.attributes?.care_giver
          if (tmp) {return fullName(tmp)}
        },
        hrefFunction: (data: any) => {
          const tmp = data.item.attributes?.segment?.attributes?.careGiverInstance
          if (tmp) {return resourceUrl(tmp)}
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.createdAt",
      title: "Erstellt",
      sortable: true,
      options: {type: 'datetime'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.presentedAt",
      title: "Vorgestellt",
      sortable: true,
      options: {type: 'datetime'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.cgiArrival",
      title: "Anreise",
      sortable: true,
      options: {
        contentFunction: (data: any) => {
          return data.item.attributes.segment.attributes.cgiArrival
          // const tmp = data.item.attributes?.segment?.attributes?.careGiverInstance?.attributes?.care_giver
          // if (tmp) {return fullName(tmp)}
        },
        type: 'date'
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.cgiDeparture",
      title: "Abreise",
      sortable: true,
      options: {type: 'date'}
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   key: "attributes.rejectionReason",
    //   title: "Absage Grund",
    //   sortable: true,
    // },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bestätigung',
            icon: ['fad', 'user-check'],
            action: actions.confirm,
          },
          {
            title: 'Abbrechen',
            icon: ['fad', 'user-slash'],
            action: actions.cancel,
          }
        ]
      }
    }
  ]
}

export const providerSuggestionIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "attributes.stage",
    title: "Status",
    // sortable: true,
    options: {
      hrefFunction: (data: any) => {
        return resourceUrl(data.item)
      }
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.createdAt",
  //   title: "Erhalten",
  //   sortable: true,
  //   options: {type: 'datetime'}
  // },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "attributes.segment.attributes.careGiverInstance",
    title: "BK",
    // sortable: true,
    options: {
      contentFunction: (data: any) => {
        const tmp = data.item.attributes?.segment?.attributes?.care_giver_instance?.attributes?.care_giver
        if (tmp) {return fullName(tmp)}
      },
      hrefFunction: (data: any) => {
        const tmp = data.item.attributes?.segment?.attributes?.care_giver_instance
        if (tmp) {return resourceUrl(tmp)}
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.contractId",
    title: "Wechselvorschlag",
    options: {
      contentFunction: (data: any) => {
        const item = data.item.relationships.contract.data?.id
        return item ? true : false
      },
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.createdAt",
    title: "Erstellt am",
    // sortable: true,
    options: {type: 'date'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.presentedAt",
    title: "Vorgestellt",
    // sortable: true,
    options: {type: 'date'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.availableFrom",
    title: "Verfügbar ab",
    // sortable: true,
    options: {
      contentFunction: (data: any) => data.item.attributes.segment.attributes.agStart,
      type: 'date'
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.rejectionReason",
  //   title: "Absage Grund",
  //   sortable: true,
  // },
]
