
  import { defineComponent, ref, watch, computed, getCurrentInstance } from "vue"
  import {apiDataMorpher } from '@/utils/apiDataHelper'
  import { resourceUrl, fullName } from "@/utils/dataExtractors"

  interface Props {
    getItems: Function,
    columns: Object[],
    options: {
      [key: string]: any
    }
  }

  export default defineComponent({
    props: {
      getItems: {
        type: Function,
        required: false
      },
      columns: {
        type: Array,
        required: false
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const searchResult = ref()
      const totalItems = ref(0)
      const searchPhrase = ref('')
      const pageOptions = [10, 25, 50]
      const perPage = ref(pageOptions[0])
      const currentPage = ref(1)
      const selectedName = ref<string>('')
      const showLoading = ref(false)
      const placeholder = props.options?.placeholder || 'Nach Vor- oder Nachnamen suchen'

      watch([perPage, currentPage], ([newPerPageValue, newCurrentPageValue]) => {
        search(null, newCurrentPageValue, newPerPageValue)
      })

      const defaultColumns = [
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.care_giver.attributes.firstName",
          title: "Vorname",
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.care_giver.attributes.lastName",
          title: "Nachname",
          options: {
            hrefFunction: (data: any) => resourceUrl(data.item),
            target: '_blank'
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
          key: "Auswählen",
          label: "Auswählen",
          sortable: false,
          options: {
            buttons: [
              {
                title: 'Auswählen',
                icon: ['fa', 'mouse-pointer'],
                action: (data: any) => {
                  selectedName.value = fullName(data.item.attributes.care_giver)
                  emit('result', data.item)
                }
              },
            ]
          }
        }
      ]

      const searchColumns = props.options.columns || defaultColumns

      const search = async (event: any, pageNumber: number = 1, pageSize: number = 10) => {
        const phrase = searchPhrase.value
        if (phrase.length < 3) return
        const query = {
          'filter[phrase]': phrase,
          'page[number]': pageNumber,
          'page[size]': pageSize,
          'include': 'care_giver,file_meta_datas'
        }
        let result
        showLoading.value = true
        if (props.options.searchFunction) {
          result = await props.options.searchFunction(query)
        } else {
          result = await root.$store.dispatch('care_giver_instances/search', query)
          apiDataMorpher(result, 'care_giver,file_meta_datas')
        }
        totalItems.value = result.meta?.totalItems ?? 0
        searchResult.value = result.data
        showLoading.value = false
      }

      return {
        searchResult,
        totalItems,
        perPage,
        pageOptions,
        currentPage,
        search,
        searchPhrase,
        searchColumns,
        selectedName,
        showLoading,
        placeholder
      }
    }
  })
